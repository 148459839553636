class NetworkingUtilities {
  /**
   * Fully removes any keys from an object payload whose values are
   * null or undefined. Values that are explicitly `false` will NOT
   * be filtered out.
   *
   * Useful for when the backend doesn't ignore undefined or null values,
   * but you don't intend on the backend doing anything with these
   * parameters.
   *
   * @param payload Any object payload you intend to send to the backend
   */
  static stripOutNullishValuesInPayload<T extends Record<any, any>>(
    payload: T
  ) {
    const filteredPayload: Record<any, any> = {}
    for (const key in payload) {
      if (!payload.hasOwnProperty(key)) continue

      if (payload[key] !== undefined && payload[key] !== null) {
        filteredPayload[key] = payload[key]
      }
    }
    return filteredPayload as T
  }
}

export default NetworkingUtilities
