import { Avatar, Button, Flex } from '@nextbusiness/infinity-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { useRootStore } from '../../../stores/RootStoreContext'
import './ProjectPeopleFilter.scss'

interface ProjectPeopleFilterProps {}

const ProjectPeopleFilter = (props: ProjectPeopleFilterProps) => {
  const { organisationStore, tasksStore } = useRootStore()

  const currentPerson = tasksStore.taskAssigneeFilter
  const setAssigneeFilter = (person: string | undefined) =>
    runInAction(() => {
      tasksStore.taskAssigneeFilter = person
      tasksStore.loadListTasks()
    })

  return (
    <Flex
      className={
        'project-people-filter' + (currentPerson ? ' filter-active' : '')
      }
      verticalAlignment='center'
    >
      {organisationStore.members?.map((member) => (
        <Avatar
          key={member.id}
          className={member.id === currentPerson ? 'current-filter' : undefined}
          name={organisationStore.fullNameOfMember(member.id!)}
          onClick={() => {
            if (currentPerson === member.id) {
              setAssigneeFilter(undefined)
            } else {
              setAssigneeFilter(member.id)
            }
          }}
        />
      ))}
      <Button
        disabled={!currentPerson}
        className='reset-filter'
        aria-label='Filter zurücksetzen'
        iconOnly='dismiss'
        variant='quaternary'
        style={{ visibility: currentPerson ? 'visible' : 'hidden' }}
        onClick={() => setAssigneeFilter(undefined)}
      />
    </Flex>
  )
}

export default observer(ProjectPeopleFilter)
