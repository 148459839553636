import { Flex } from '@nextbusiness/infinity-ui'
import React from 'react'
import ProjectPeopleFilter from './ProjectPeopleFilter'
import ProjectStatusFilter from './ProjectStatusFilter'

interface ProjectFiltersProps {}

const ProjectFilters = (props: ProjectFiltersProps) => (
  <Flex className='project-filter' spacing='tiny'>
    <ProjectStatusFilter />
    <ProjectPeopleFilter />
  </Flex>
)

export default ProjectFilters
