import { RichSelect } from '@nextbusiness/infinity-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { TaskStatusFilter } from '../../../libs/tasks/TaskStatusFilter'
import { useRootStore } from '../../../stores/RootStoreContext'

interface ProjectStatusFilterProps {}

const ProjectStatusFilter = (props: ProjectStatusFilterProps) => {
  const { tasksStore } = useRootStore()

  const setStatusFilter = (statusFilter: TaskStatusFilter) =>
    runInAction(() => {
      tasksStore.listTaskStatusFilter = statusFilter
      tasksStore.loadListTasks()
    })

  return (
    <RichSelect
      style={{ width: '20rem' }}
      value={tasksStore.listTaskStatusFilter}
      onChange={(selectedFilter) =>
        setStatusFilter(selectedFilter as TaskStatusFilter)
      }
      options={[
        {
          value: 'all',
          label: 'Alle Tasks',
        },
        {
          value: 'open',
          label: 'Offene Tasks',
        },
        {
          value: 'blocked',
          label: 'Nur blockierte Tasks',
        },
        {
          value: 'abandoned',
          label: 'Verworfene Tasks',
        },
        {
          value: 'done',
          label: 'Abgeschlossene Tasks',
        },
      ]}
    />
  )
}

export default observer(ProjectStatusFilter)
