import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import ITask, { TaskStatus as TaskStatusType } from '../../../model/Task'
import Tasks from '../../../networking/Tasks'
import { useRootStore } from '../../../stores/RootStoreContext'
import './TaskStatus.scss'
import TaskStatusChangeFlyout from './TaskStatusChangeFlyout'
import TaskStatusIndicator, { labelForStatus } from './TaskStatusIndicator'

interface TaskStatusProps {
  task: ITask
}

const TaskStatus = (props: TaskStatusProps) => {
  const { authenticationStore, tasksStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const status: TaskStatusType = props.task.status
  const statusLabel = labelForStatus(status)

  const [isStatusChangeFlyoutOpen, setIsStatusChangeFlyoutOpen] =
    useState<boolean>(false)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false)

  const changeStatus = async (status: TaskStatusType) => {
    setIsUpdatingStatus(true)
    try {
      await Tasks.updateTask(
        props.task.id,
        authenticationStore.organisationIdentifier!,
        {
          status,
        }
      )
      await tasksStore.loadListTasks()
    } catch (error: any) {
      notificationCenter.addNotification({
        children: `Status konnte nicht zu «${labelForStatus(
          status
        )}» geändert werden. ${error.message ?? 'Bitte versuche es erneut.'}`,
        variant: 'error',
      })
    } finally {
      setIsUpdatingStatus(false)
    }
  }

  return (
    <div className='task-status'>
      <TaskStatusChangeFlyout
        task={props.task}
        isActive={isStatusChangeFlyoutOpen}
        setIsActive={setIsStatusChangeFlyoutOpen}
        trigger={
          <Button
            className='task-status-toggle'
            variant='quaternary'
            aria-label='Status ändern'
            tooltip={statusLabel}
            onClick={() => setIsStatusChangeFlyoutOpen(true)}
            isLoading={isUpdatingStatus}
          >
            <TaskStatusIndicator status={status} />
          </Button>
        }
        onTaskStatusPicked={(status) => changeStatus(status)}
      />
    </div>
  )
}

export default observer(TaskStatus)
