import {
  ErrorBoundary,
  ThemeProvider,
  TypographyBase,
} from '@nextbusiness/infinity-ui'
import '@nextbusiness/infinity-ui/dist/index.css'
import { configure } from 'mobx'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/index.scss'
import reportWebVitals from './utility/reportWebVitals'

configure({
  enforceActions: 'never',
})

ReactDOM.render(
  <React.StrictMode>
    <TypographyBase>
      <ThemeProvider primaryColor='cyan'>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </TypographyBase>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
