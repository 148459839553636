import IProject, { INewProject, IProjectModification } from '../model/Project'
import ITask from '../model/Task'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import {
  DEFAULT_HEADERS,
  DEFAULT_ERROR,
  FABRIC_BASE_URL,
} from './NetworkingConstants'

const load = async (organisationIdentifier: string) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/projects${accessQueryParameters(
        organisationIdentifier
      )}`,
    DEFAULT_HEADERS
  )
  const body = await response.json()
  if (!response.ok || !body.projects) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'You are not a member of this organisation':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new BackendError(
          ErrorType.ServerError,
          body?.message ?? DEFAULT_ERROR
        )
    }
  }
  return body.projects as IProject[]
}

const createProject = async (
  organisationIdentifier: string,
  project: INewProject
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/project${accessQueryParameters(
        organisationIdentifier
      )}`,
    {
      ...DEFAULT_HEADERS,
      method: 'POST',
      body: JSON.stringify(project),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.project) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'You are not an admin of this organisation':
        throw new BackendError(
          ErrorType.InsufficientUserPrivilege,
          body.message
        )
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new BackendError(
          ErrorType.ServerError,
          body?.message ?? DEFAULT_ERROR
        )
    }
  }
  return body.project as IProject
}

const updateProject = async (
  projectId: string,
  organisationIdentifier: string,
  project: IProjectModification
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/project/${projectId}${accessQueryParameters(organisationIdentifier)}`,
    {
      ...DEFAULT_HEADERS,
      method: 'PATCH',
      body: JSON.stringify(project),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'You are not an admin of this organisation':
        throw new BackendError(
          ErrorType.InsufficientUserPrivilege,
          body.message
        )
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Could not find this project':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.project as IProject
}

const deleteProject = async (
  projectId: string,
  organisationIdentifier: string
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/project/${projectId}${accessQueryParameters(organisationIdentifier)}`,
    {
      ...DEFAULT_HEADERS,
      method: 'DELETE',
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'You are not an admin of this organisation':
        throw new BackendError(
          ErrorType.InsufficientUserPrivilege,
          body.message
        )
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Could not find this project':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.task as ITask
}

const Projects = {
  load,
  createProject,
  updateProject,
  deleteProject,
}

export default Projects
