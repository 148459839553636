import { Flyout, TitledButtonGroups } from '@nextbusiness/infinity-ui'
import React from 'react'
import ITask, { TaskStatus } from '../../../model/Task'
import './TaskStatusChangeFlyout.scss'
import TaskStatusIndicator, { labelForStatus } from './TaskStatusIndicator'

interface TaskStatusChangeFlyoutProps {
  isActive: boolean
  setIsActive: (isActive: boolean) => void
  trigger: React.ReactNode
  task: ITask
  onTaskStatusPicked: (status: TaskStatus) => void
}

const StatusOption = (props: { status: TaskStatus }) => {
  return (
    <>
      <TaskStatusIndicator status={props.status} />
      <span>{labelForStatus(props.status)}</span>
    </>
  )
}

const TASK_STATUS_OPTIONS: TaskStatus[] = [
  'progress',
  'done',
  'blocked',
  'abandoned',
  'icebox',
]

const TaskStatusChangeFlyout = (props: TaskStatusChangeFlyoutProps) => (
  <Flyout {...props} triggerIsButton className='task-status-change-flyout'>
    <TitledButtonGroups
      closeMenu={() => props.setIsActive(false)}
      groups={[
        {
          title: '',
          buttons: TASK_STATUS_OPTIONS.filter(
            (option) => option !== props.task.status
          ).map((option) => ({
            text: (<StatusOption status={option} />) as unknown as string,
            onClick: () => props.onTaskStatusPicked(option),
          })),
        },
      ]}
    />
  </Flyout>
)

export default TaskStatusChangeFlyout
