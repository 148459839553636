import { Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import React from 'react'

const TaskDetailPaneEmptyState = () => (
  <div className='task-detail-pane no-selection'>
    <Flex verticalAlignment='center' horizontalAlignment='center' fillContainer>
      <NonIdealState>Kein Task ausgewählt</NonIdealState>
    </Flex>
  </div>
)

export default TaskDetailPaneEmptyState
