import { Avatar } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import ITask from '../../../model/Task'
import { useRootStore } from '../../../stores/RootStoreContext'
import './TaskListItem.scss'
import TaskStatus from './TaskStatus'

interface TaskListItemProps {
  task: ITask
  onSelected?: () => void
}

const TaskListItem = (props: TaskListItemProps) => {
  const { organisationStore } = useRootStore()
  return (
    <div className='task-list-item' tabIndex={0} onClick={props.onSelected}>
      <TaskStatus task={props.task} />
      <div className='task-title'>
        <span>{props.task.title}</span>
      </div>
      <div className='task-assignees'>
        {props.task.assignees?.map((assignee) => (
          <Avatar
            key={assignee}
            name={organisationStore.fullNameOfMember(assignee)}
          />
        ))}
      </div>
    </div>
  )
}

export default observer(TaskListItem)
