import { Icon, RichSelect } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useRootStore } from '../../../stores/RootStoreContext'
import CreateProjectDialog from '../creation/CreateProjectDialog'

interface ProjectPickerProps {
  selectedProjectId?: string | null
  onChange: (projectId?: string | null) => void
}

const CREATE_NEW_PROJECT_KEY = '__new-project'

const ProjectPicker = (props: ProjectPickerProps) => {
  const { projectStore } = useRootStore()

  const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] =
    useState<boolean>(false)
  const [forceSelectRerenderKey, setForceSelectRerenderKey] =
    useState<number>(0)

  const forceSelectToRerender = () =>
    setForceSelectRerenderKey(forceSelectRerenderKey + 1)

  return (
    <>
      <RichSelect
        style={{ width: '24rem' }}
        key={forceSelectRerenderKey}
        value={props.selectedProjectId}
        onChange={(option) => {
          if (option === CREATE_NEW_PROJECT_KEY) {
            props.onChange(props.selectedProjectId)
            setIsCreateProjectDialogOpen(true)
            forceSelectToRerender()
          } else {
            props.onChange(option as string)
          }
        }}
        options={[
          {
            label: 'Neues Projekt',
            value: CREATE_NEW_PROJECT_KEY,
            icon: <Icon icon='add' size={22} />,
          },
          ...(projectStore.projects?.map((project) => ({
            label: project.name,
            value: project.id,
            icon: <Icon icon='folder' size={22} />,
          })) ?? []),
        ]}
      />
      <CreateProjectDialog
        isOpen={isCreateProjectDialogOpen}
        onDismiss={() => setIsCreateProjectDialogOpen(false)}
        onProjectCreated={(projectId) => {
          setIsCreateProjectDialogOpen(false)
          props.onChange(projectId)
          forceSelectToRerender()
        }}
      />
    </>
  )
}

export default observer(ProjectPicker)
