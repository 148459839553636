import { Button, Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import ITask from '../../../model/Task'
import Tasks from '../../../networking/Tasks'
import { useRootStore } from '../../../stores/RootStoreContext'

interface TaskDetailHeaderActionsProps {
  task: ITask
}

const TaskDetailHeaderActions = (props: TaskDetailHeaderActionsProps) => {
  const { authenticationStore, tasksStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteTask = async () => {
    setIsDeleting(true)
    try {
      await Tasks.deleteTask(
        props.task.id,
        authenticationStore.organisationIdentifier!
      )
      await tasksStore.loadListTasks()
    } catch (error: any) {
      notificationCenter.addNotification({
        children: `Task konnte nicht gelöscht werden. ${
          error.message ?? 'Lade die Seite neu und versuche es erneut.'
        }`,
        variant: 'error',
      })
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Flex className='header-task-actions' horizontalAlignment='flex-end'>
      <Button
        iconOnly='delete'
        variant='quaternary'
        onClick={() => deleteTask()}
        isLoading={isDeleting}
      />
    </Flex>
  )
}

export default observer(TaskDetailHeaderActions)
