import { Button, Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import React, { useEffect, useState } from 'react'
import Editor from '../../../base-components/editor/Editor'
import ITask from '../../../model/Task'
import Tasks from '../../../networking/Tasks'
import { useRootStore } from '../../../stores/RootStoreContext'

interface TaskDetailDescriptionProps {
  task: ITask
}

const TaskDetailDescription = (props: TaskDetailDescriptionProps) => {
  const { authenticationStore, organisationStore, tasksStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [description, setDescription] = useState<any>()
  const [isEditingDescription, setIsEditingDescription] =
    useState<boolean>(false)
  const [forceRerenderEditorKey, setForceRerenderEditorKey] =
    useState<number>(0)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const forceEditorRerender = () =>
    setForceRerenderEditorKey(forceRerenderEditorKey + 1)
  const loadDescriptionFromProps = () => {
    try {
      if (props.task.description) {
        setDescription(JSON.parse(props.task.description))
      } else {
        setDescription(undefined)
      }
    } catch {
      setDescription(undefined)
    }
    forceEditorRerender()
  }

  useEffect(() => {
    loadDescriptionFromProps()
  }, [props.task.description, props.task.id])

  const resetEditor = () => {
    loadDescriptionFromProps()
    setIsEditingDescription(false)
  }

  const updateDescription = async () => {
    setIsSaving(true)
    try {
      await Tasks.updateTask(
        props.task.id,
        authenticationStore.organisationIdentifier!,
        {
          description: JSON.stringify(description),
        }
      )
      await tasksStore.loadListTasks()

      notificationCenter.addNotification({
        children: `Beschreibung gespeichert`,
        variant: 'success',
      })
      setIsEditingDescription(false)
    } catch (error: any) {
      notificationCenter.addNotification({
        children: `Beschreibung konnte nicht gespeichert werden.. ${
          error.message ?? 'Lade die Seite neu und versuche es erneut.'
        }`,
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className='task-detail-area area-description'>
      {(description || isEditingDescription) && (
        <Editor
          content={description}
          key={forceRerenderEditorKey}
          onChange={(content) => setDescription(content)}
          tall={isEditingDescription}
          users={organisationStore.membersAsEditorAtoms}
          viewingOnly={!isEditingDescription}
        />
      )}
      {!isEditingDescription ? (
        <Button
          iconLeft='edit'
          onClick={() => setIsEditingDescription(true)}
          variant='quaternary'
        >
          Beschreibung bearbeiten
        </Button>
      ) : (
        <Flex spacing='tiny'>
          <Button
            iconLeft='check'
            variant='primary'
            onClick={() => updateDescription()}
            isLoading={isSaving}
          >
            Speichern
          </Button>
          <Button onClick={() => resetEditor()} disabled={isSaving}>
            Verwerfen
          </Button>
        </Flex>
      )}
    </div>
  )
}

export default TaskDetailDescription
