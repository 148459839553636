import React, { useMemo } from 'react'
import ITask from '../../../model/Task'
import DateUtilities from '../../../utility/DateUtilites'
import TaskListItem from '../task/TaskListItem'
import './TaskList.scss'

interface TaskListProps {
  tasks: ITask[]
  skipLastDivider?: boolean
  onTaskSelected?: (task: ITask) => void
}

const TaskList = (props: TaskListProps) => {
  const sortedTasks = useMemo(
    () =>
      props.tasks.sort((a, b) => {
        const taskADate = DateUtilities.dateFromISO(a.createdAt)
        const taskBDate = DateUtilities.dateFromISO(b.createdAt)

        return taskADate < taskBDate ? 1 : -1
      }),
    [props.tasks]
  )
  return (
    <div className='task-list'>
      {sortedTasks.map((task, index) => {
        const isLastListItem = index === sortedTasks.length - 1
        return (
          <React.Fragment key={task.id}>
            <TaskListItem
              task={task}
              onSelected={() => {
                if (props.onTaskSelected) props.onTaskSelected(task)
              }}
            />
            {(!isLastListItem || !props.skipLastDivider) && (
              <div className='divider' />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default TaskList
