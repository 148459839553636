import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import {
  tasksFilteredByStatusFilter,
  TaskStatusFilter,
} from '../libs/tasks/TaskStatusFilter'
import ITask, { ITaskFilterQuery } from '../model/Task'
import Tasks from '../networking/Tasks'
import NetworkingUtilities from '../utility/NetworkingUtilities'
import RootStore from './RootStore'
import Store from './Store'

export default class TasksStore extends Store {
  @persist @observable listTaskStatusFilter: TaskStatusFilter = 'open'
  @persist @observable taskAssigneeFilter: string | undefined = undefined

  @observable listTasks: ITask[] = []
  @observable isLoadingTasks: boolean = true

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  async loadListTasks() {
    this.isLoadingTasks = true

    const filter: ITaskFilterQuery =
      NetworkingUtilities.stripOutNullishValuesInPayload({
        project: this.rootStore.projectStore.selectedProject || undefined,
        assignees: this.taskAssigneeFilter,
      })
    const tasks = await Tasks.load(
      filter,
      this.rootStore.authenticationStore.organisationIdentifier!
    )

    runInAction(() => {
      this.listTasks = tasksFilteredByStatusFilter(
        tasks,
        this.listTaskStatusFilter
      )
      this.isLoadingTasks = false
    })
  }

  @action
  reset() {
    this.listTasks = []
    this.isLoadingTasks = true
    this.listTaskStatusFilter = 'open'
    this.taskAssigneeFilter = undefined
  }
}
