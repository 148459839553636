import React from 'react'
import { TaskStatus } from '../../../model/Task'
import './TaskStatusIndicator.scss'

interface TaskStatusIndicatorProps {
  status: TaskStatus
}

export const labelForStatus = (status: TaskStatus) => {
  switch (status) {
    case 'icebox':
      return 'To Do'
    case 'progress':
      return 'In Arbeit'
    case 'blocked':
      return 'Blockiert'
    case 'done':
      return 'Erledigt'
    case 'abandoned':
      return 'Verworfen'
  }
}

const TaskStatusIndicator = (props: TaskStatusIndicatorProps) => (
  <div
    className={`task-status-indicator status-${props.status}`}
    aria-label={labelForStatus(props.status)}
  />
)

export default TaskStatusIndicator
