import {
  Flex,
  InputField,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import React, { useEffect, useRef, useState } from 'react'
import ITask from '../../../model/Task'
import Tasks from '../../../networking/Tasks'
import { useRootStore } from '../../../stores/RootStoreContext'
import TaskStatusIndicator from '../task/TaskStatusIndicator'
import './TaskComposer.scss'

interface TaskComposerProps {
  onTaskCreated?: (task: ITask) => void
  dismissTaskComposer: () => void
}

const TaskComposer = (props: TaskComposerProps) => {
  const { authenticationStore, projectStore, tasksStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [taskTitle, setTaskTitle] = useState<string>('')
  const [isCreatingTask, setIsCreatingTask] = useState<boolean>(false)

  const inputFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputFieldRef.current?.focus()
  }, [])

  const createTask = async () => {
    if (!taskTitle || !projectStore.selectedProject) return
    try {
      setIsCreatingTask(true)
      const task = await Tasks.createTask(
        authenticationStore.organisationIdentifier!,
        projectStore.selectedProject!,
        {
          status: 'icebox',
          title: taskTitle,
        }
      )
      await tasksStore.loadListTasks()
      if (props.onTaskCreated) props.onTaskCreated(task)
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          error?.message ??
          'Der Task konnte nicht erstellt werden. Bitte versuche es erneut.',
        variant: 'error',
      })
    } finally {
      setIsCreatingTask(false)
    }
  }

  return (
    <Flex className={'task-composer' + (isCreatingTask ? ' is-creating' : '')}>
      <div className='task-status-placeholder'>
        <TaskStatusIndicator status='icebox' />
      </div>
      <div className='task-title'>
        <InputField
          ref={inputFieldRef}
          className='task-title-field'
          bare
          value={taskTitle}
          onChange={setTaskTitle}
          disabled={isCreatingTask}
          onKeyDown={(e) => {
            if (e.key === 'Enter') createTask()
            if (e.key === 'Escape') props.dismissTaskComposer()
          }}
        />
      </div>
    </Flex>
  )
}

export default TaskComposer
