import ITask, {
  INewTask,
  ITaskFilterQuery,
  ITaskModification,
} from '../model/Task'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { buildFilterQuery, FilterQuery } from './FilterQuery'
import {
  DEFAULT_HEADERS,
  DEFAULT_ERROR,
  FABRIC_BASE_URL,
} from './NetworkingConstants'

const load = async (
  filterQuery: ITaskFilterQuery,
  organisationIdentifier: string
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/tasks${accessQueryParameters(
        organisationIdentifier
      )}${buildFilterQuery(filterQuery as FilterQuery)}`,
    DEFAULT_HEADERS
  )
  const body = await response.json()
  if (!response.ok || !body.tasks) {
    switch (body.message) {
      case 'Unauthorised':
      case 'You are not a member of this organisation':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new BackendError(
          ErrorType.ServerError,
          body?.message ?? DEFAULT_ERROR
        )
    }
  }
  return body.tasks as ITask[]
}

const createTask = async (
  organisationIdentifier: string,
  projectId: string,
  task: INewTask
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/project/${projectId}/task${accessQueryParameters(
        organisationIdentifier
      )}`,
    {
      ...DEFAULT_HEADERS,
      method: 'POST',
      body: JSON.stringify(task),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
      case 'You are not a member of this organisation':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Required fields are missing':
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Could not find this project':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(
          ErrorType.ServerError,
          body?.message ?? DEFAULT_ERROR
        )
    }
  }
  return body.task as ITask
}

const updateTask = async (
  taskId: string,
  organisationIdentifier: string,
  changes: ITaskModification
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/task/${taskId}${accessQueryParameters(organisationIdentifier)}`,
    {
      ...DEFAULT_HEADERS,
      method: 'PATCH',
      body: JSON.stringify(changes),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
      case 'You are not a member of this organisation':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Could not find this task':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.task as ITask
}

const deleteTask = async (taskId: string, organisationIdentifier: string) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/task/${taskId}${accessQueryParameters(organisationIdentifier)}`,
    {
      ...DEFAULT_HEADERS,
      method: 'DELETE',
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Could not find this task':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.task as ITask
}

const Tasks = {
  load,
  createTask,
  updateTask,
  deleteTask,
}

export default Tasks
