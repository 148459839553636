export type FilterQuery = { [key: string]: string | number | boolean }

export const buildFilterQuery = (filterQuery: FilterQuery) => {
  let query = ''
  for (const key in filterQuery) {
    if (!filterQuery.hasOwnProperty(key)) continue

    query += `&${encodeURIComponent(key)}=${encodeURIComponent(
      filterQuery[key]
    )}`
  }
  return query
}
