import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Permission } from '../base-components/system/Permission'
import RequestPermission from '../base-components/system/RequestPermission'
import { useRootStore } from '../stores/RootStoreContext'

const CriticalPermissionMissingPage = () => {
  const { authenticationStore } = useRootStore()

  if (
    !authenticationStore.organisationIdentifier ||
    !authenticationStore.isAuthenticated
  )
    return null

  return (
    <Flex
      fillContainer
      horizontalAlignment='center'
      verticalAlignment='center'
      style={{ height: 'calc(100vh - 5.4rem)' }}
    >
      <NonIdealState
        icon='lock'
        title='Fehlende Zugriffsrechte'
        actions={[
          {
            children: 'Neu starten',
            onClick: () => {
              document.location.reload()
            },
          },
        ]}
      >
        <Flex direction='vertical'>
          <Text type='paragraph'>
            Tasks hat keinen Zugriff auf die Projekte und Aufgaben in Infinity.
            <br />
            Dies ist erforderlich, damit die Tasks-App funktioniert.
          </Text>
        </Flex>
      </NonIdealState>
      <RequestPermission
        permissions={[
          Permission.WriteProjects,
          Permission.ReadProjects,

          /** Temporary: Due to a backend bug, contact permissions are also
           * required at the moment. Once IF-171 is resolved in the backend,
           * these permissions can be removed from this array.
           */
          Permission.ReadContacts,
          Permission.WriteContacts,
        ]}
        onPermissionGranted={() => {
          authenticationStore.logout()
          document.location.reload()
        }}
      />
    </Flex>
  )
}

export default observer(CriticalPermissionMissingPage)
