import {
  Dialog,
  Flex,
  InputField,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Projects from '../../../networking/Projects'
import { useRootStore } from '../../../stores/RootStoreContext'

interface CreateProjectDialogProps {
  isOpen: boolean
  onDismiss: () => void
  onProjectCreated: (projectId: string) => void
}

const CreateProjectDialog = (props: CreateProjectDialogProps) => {
  const { authenticationStore, projectStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [projectName, setProjectName] = useState<string>('')
  const [isCreationInProgress, setIsCreationInProgress] =
    useState<boolean>(false)

  useEffect(() => {
    if (props.isOpen) setProjectName('')
  }, [props.isOpen])

  useEffect(() => {
    const keyboardListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && projectName) createProject()
    }

    document.addEventListener('keypress', keyboardListener)
    return () => document.removeEventListener('keypress', keyboardListener)
  })

  const createProject = async () => {
    setIsCreationInProgress(true)
    try {
      const project = await Projects.createProject(
        authenticationStore.organisationIdentifier!,
        {
          name: projectName,
        }
      )
      await projectStore.loadProjects()
      props.onProjectCreated(project.id)
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          error.message ??
          'Das Projekt konnte nicht erstellt werden. Bitte versuche es erneut.',
        variant: 'error',
      })
    } finally {
      setIsCreationInProgress(false)
    }
  }

  return (
    <Dialog
      title='Neues Projekt'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      dismissable
      initialFocusIndex={1}
      icon='folder'
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
          disabled: isCreationInProgress,
          variant: 'tertiary',
        },
        {
          children: 'Erstellen',
          onClick: () => createProject(),
          isLoading: isCreationInProgress,
          disabled: !projectName,
          variant: 'primary',
        },
      ]}
    >
      <Flex direction='vertical'>
        <InputField
          label='Titel'
          value={projectName}
          onChange={setProjectName}
        />
      </Flex>
    </Dialog>
  )
}

export default observer(CreateProjectDialog)
