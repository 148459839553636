import { ApplicationFrame } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import AuthenticationPage from './pages/AuthenticationPage'
import NotFoundPage from './pages/NotFoundPage'
import ProjectPage from './pages/projects/ProjectPage'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import UnauthenticatedRoute from './routes/UnauthenticatedRoute'
import { useRootStore } from './stores/RootStoreContext'
import { useQuery } from './utility/hooks'

const ApplicationSwitch = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const oneTimeToken = query.get('token')
  const organisationIdentifier = query.get('organisationIdentifier')

  return (
    <ApplicationFrame
      appTitle='Tasks'
      identifier='tasks'
      userMenu={authenticationStore.userMenu}
      history={history}
      location={location}
      primaryNavigation={[
        {
          path: '/projects',
          description: 'Projekte',
        },
      ]}
      headerProps={{
        onHomeClick: () => {
          document.location.href = 'https://staging.infinity.nextbusiness.ch/'
        },
      }}
    >
      <div className='application-switch'>
        <Switch>
          <AuthenticatedRoute
            path='/'
            exact
            redirectToIfUnauthenticated={
              oneTimeToken && organisationIdentifier
                ? `/login?token=${encodeURIComponent(
                    oneTimeToken
                  )}&organisationIdentifier=${encodeURIComponent(
                    organisationIdentifier
                  )}`
                : '/login'
            }
          >
            <Redirect to='/projects' />
          </AuthenticatedRoute>
          <UnauthenticatedRoute path='/login' exact>
            <AuthenticationPage />
          </UnauthenticatedRoute>
          <AuthenticatedRoute path='/projects' exact>
            <ProjectPage />
          </AuthenticatedRoute>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </ApplicationFrame>
  )
}

export default observer(ApplicationSwitch)
