import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import IProject, { IProjectModification } from '../model/Project'
import { BackendError, ErrorType } from '../networking/Errors'
import Projects from '../networking/Projects'
import RootStore from './RootStore'
import Store from './Store'

export default class ProjectStore extends Store {
  @persist('list') @observable projects: IProject[] | null = []

  @observable hasProjectPermission: boolean = true
  @persist @observable selectedProject: string | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  onHydrate() {
    this.loadProjects()

    if (this.selectedProject) this.rootStore.tasksStore.loadListTasks()
  }

  @action
  selectProject = (projectId: string) => {
    this.selectedProject = projectId
    this.rootStore.tasksStore.loadListTasks()
  }

  async loadProjects() {
    if (!this.rootStore.organisationStore.currentIdentifier) return
    try {
      const projects = await Projects.load(
        this.rootStore.organisationStore.currentIdentifier
      )
      runInAction(() => {
        this.projects = projects
        this.hasProjectPermission = true
      })
    } catch (error: any) {
      const errorType = (error as BackendError).type
      if (
        errorType === ErrorType.InsufficientPermissions ||
        errorType === ErrorType.Unauthorised
      ) {
        this.hasProjectPermission = false
      }
    }
  }

  @action
  async updateProject(projectId: string, changes: IProjectModification) {
    if (this.projects)
      this.projects = this.projects.map((project) =>
        project.id === projectId ? { ...project, ...changes } : project
      )

    await Projects.updateProject(
      projectId,
      this.rootStore.organisationStore.currentIdentifier!,
      changes
    )
    await this.loadProjects()
  }

  @action
  async deleteProject(projectId: string) {
    if (this.projects)
      this.projects = this.projects.filter(
        (project) => project.id !== projectId
      )

    await Projects.deleteProject(
      projectId,
      this.rootStore.organisationStore.currentIdentifier!
    )
    await this.loadProjects()
  }

  @action
  reset() {
    this.projects = []
  }
}
