import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Authentication from '../networking/Authentication'
import { DASHBOARD_URL, REDIRECT_URL } from '../networking/NetworkingConstants'
import { ErrorType } from '../networking/Errors'
import { useRootStore } from '../stores/RootStoreContext'
import { useQuery } from '../utility/hooks'

const AuthenticationPage = () => {
  const { authenticationStore } = useRootStore()

  const [errorMessage, setErrorMessage] = useState<string>()
  const query = useQuery()

  const oneTimeToken = query.get('token')
  const organisationIdentifier = query.get('organisationIdentifier')

  useEffect(() => {
    if (
      authenticationStore.isHydrated &&
      authenticationStore.organisationIdentifier !== organisationIdentifier
    ) {
      authenticationStore.logout()
    }

    if (
      authenticationStore.isAuthenticated ||
      !oneTimeToken ||
      !organisationIdentifier
    )
      return

    authenticateApp(oneTimeToken, organisationIdentifier)
  }, [])

  const authenticateApp = async (
    oneTimeToken: string,
    organisationIdentifier: string
  ) => {
    try {
      const [applicationAccessToken, userDataToken] =
        await Authentication.getAppAccessAndUserDataToken(
          organisationIdentifier,
          'ch.nextbusiness.infinity.tasks',
          oneTimeToken
        )
      authenticationStore.setCurrentTokens(
        applicationAccessToken,
        userDataToken
      )
      authenticationStore.setOrganisationIdentifier(organisationIdentifier)
      setErrorMessage('')
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          document.location.href = REDIRECT_URL
          return
        case ErrorType.NotFound:
          if (error.message === 'This organisation does not exist')
            return setErrorMessage(
              'Die Organisation konnte nicht gefunden werden.'
            )
          else return setErrorMessage('Die App konnte nicht gefunden werden.')
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    }
  }

  if (authenticationStore.isAuthenticated) return null
  if (!oneTimeToken || !organisationIdentifier) {
    document.location.href = REDIRECT_URL
    return null
  }

  return errorMessage ? (
    <Flex
      fillContainer
      horizontalAlignment='center'
      verticalAlignment='center'
      style={{ height: 'calc(100vh - 5.4rem)' }}
    >
      <NonIdealState
        icon='lock'
        title='Infinity Tasks konnte nicht gestartet werden.'
        actions={[
          {
            children: 'Zurück zum Dashboard',
            onClick: () => {
              document.location.href = DASHBOARD_URL
            },
            variant: 'tertiary',
          },
          {
            children: 'Erneut versuchen',
            onClick: () => {
              document.location.href = REDIRECT_URL
            },
          },
        ]}
      >
        <Flex direction='vertical'>
          <Text type='paragraph'>{errorMessage}</Text>
        </Flex>
      </NonIdealState>
    </Flex>
  ) : null
}

export default observer(AuthenticationPage)
