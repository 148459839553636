import { Flex, InlineEdit } from '@nextbusiness/infinity-ui'
import React from 'react'
import ITask from '../../../model/Task'
import TaskStatus from '../task/TaskStatus'

interface TaskDetailHeaderTitleProps {
  task: ITask
}

const TaskDetailHeaderTitle = (props: TaskDetailHeaderTitleProps) => (
  <Flex className='header-task-title' verticalAlignment='center'>
    <TaskStatus task={props.task} />
    <InlineEdit className='task-title' initialValue={props.task.title} />
  </Flex>
)

export default TaskDetailHeaderTitle
