import React from 'react'
import './OptionsBar.scss'

interface OptionsBarProps {
  children?: React.ReactNode
  className?: string
}

const OptionsBar = (props: OptionsBarProps) => (
  <div
    className={'options-bar' + (props.className ? ` ${props.className}` : '')}
  >
    {props.children}
  </div>
)

export default OptionsBar
