import { Button, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import OptionsBar from '../../components/layout/options-bar/OptionsBar'
import ProjectFilters from '../../components/projects/project-filter/ProjectFilters'
import ProjectPicker from '../../components/projects/project-picker/ProjectPicker'
import TaskComposer from '../../components/tasks/task-composer/TaskComposer'
import TaskDetailPane from '../../components/tasks/task-detail/TaskDetailPane'
import TaskList from '../../components/tasks/task-list/TaskList'
import ITask from '../../model/Task'
import { useRootStore } from '../../stores/RootStoreContext'
import './ProjectPage.scss'

interface ProjectPageProps {}

const ProjectPage = (props: ProjectPageProps) => {
  const { projectStore, tasksStore } = useRootStore()

  const [isTaskComposerOpen, setIsTaskComposerOpen] = useState<boolean>(false)

  const [selectedTaskId, setSelectedTaskId] = useState<string>()
  const selectedTask: ITask | undefined = selectedTaskId
    ? tasksStore.listTasks.find((task) => task.id === selectedTaskId)
    : undefined

  return (
    <div className='page-container'>
      <div className='project-page'>
        <div className='project-page-main'>
          <OptionsBar>
            <Flex spacing='tiny'>
              <ProjectPicker
                selectedProjectId={projectStore.selectedProject}
                onChange={(selectedProject) =>
                  projectStore.selectProject(selectedProject!)
                }
              />
              <ProjectFilters />
              <div style={{ flexGrow: 1 }} />
              <Button
                iconLeft='plus'
                variant='primary'
                onClick={() => setIsTaskComposerOpen(true)}
              >
                Task
              </Button>
            </Flex>
          </OptionsBar>
          <div className='project-task-list'>
            {isTaskComposerOpen && (
              <TaskComposer
                onTaskCreated={() => setIsTaskComposerOpen(false)}
                dismissTaskComposer={() => setIsTaskComposerOpen(false)}
              />
            )}
            <TaskList
              tasks={tasksStore.listTasks}
              onTaskSelected={(task) => setSelectedTaskId(task.id)}
            />
          </div>
        </div>
        <div className='project-page-detail'>
          <TaskDetailPane task={selectedTask} />
        </div>
      </div>
    </div>
  )
}

export default observer(ProjectPage)
