import React from 'react'
import Task from '../../../model/Task'
import TaskDetailDescription from './TaskDetailDescription'
import TaskDetailHeaderActions from './TaskDetailHeaderActions'
import TaskDetailHeaderTitle from './TaskDetailHeaderTitle'
import './TaskDetailPane.scss'
import TaskDetailPaneEmptyState from './TaskDetailPaneEmptyState'

interface TaskDetailPaneProps {
  task?: Task
}

const TaskDetailPane = (props: TaskDetailPaneProps) => {
  if (!props.task) return <TaskDetailPaneEmptyState />

  return (
    <div className='task-detail-pane'>
      <div className='pane-header'>
        <TaskDetailHeaderActions task={props.task} />
        <TaskDetailHeaderTitle task={props.task} />
      </div>
      <div className='pane-content'>
        <TaskDetailDescription task={props.task} />
      </div>
    </div>
  )
}

export default TaskDetailPane
