import ITask from '../../model/Task'

export type TaskStatusFilter = 'open' | 'done' | 'blocked' | 'all' | 'abandoned'

export const tasksFilteredByStatusFilter = (
  tasks: ITask[],
  filter: TaskStatusFilter
) =>
  // eslint-disable-next-line array-callback-return
  tasks.filter((task) => {
    switch (filter) {
      case 'all':
        return true
      case 'blocked':
        return task.status === 'blocked'
      case 'done':
        return task.status === 'done'
      case 'abandoned':
        return task.status === 'abandoned'
      case 'open':
        return task.status !== 'done' && task.status !== 'abandoned'
    }
  })
